import axios from 'axios';


/**
 * 
 * Helper function to make API requests
 * 
 */
export function apiRequest(type, endpoint, callback, param) {

	let request = process.env.NODE_ENV === 'production' ? '/api/' + endpoint : 'http://localhost:5000/api/' + endpoint;

	switch (type) {
		case 'get':
			getRequest(endpoint, request, callback);
			break;
		case 'post':
			postRequest(endpoint, request, callback, param);
			break;
		default:
			console.log("default case: request has no type");
	}
}

function getRequest(endpoint, request, callback) {
	axios
		.get(request)

		.then(res => {
			callback(res.data)
		})

		.catch(err => {
			callback('error')
			// console.log('Error from GET request ' + endpoint, err);
		})
}

function postRequest(endpoint, request, callback, param) {
	axios
		.post(request, param)

		.then(res => {
			if (res.data.length < 1) {
				callback('none')
			}
			callback(res.data)
		})

		.catch(err => {
			callback('error')
			// console.log('Error from POST request ' + endpoint, err);
		})
}