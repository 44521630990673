import React from "react";
import { useAuth0 } from "../../auth0";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useWindowSize } from '../../hooks/resizer'
import { CustomToolbar } from './CustomToolbar'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // marginBottom: theme.spacing(1),
        width: '100%'
    },
    menuButton: {
        'text-transform': 'none',
    },
    condensedButton: {
        position: 'relative',
        'text-transform': 'none',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    logoContainer: {
        width: '250px',
        // height: '15px',
        // marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    shieldLogo: {
        width: '100%',
        height: 'auto',
    },
    toolbar: {
        textAlign: 'center',
        marginTop: theme.spacing(6),
        position: 'fixed',
        'z-index': '1000',
        width: '100%'
    },
    toolPaper: {
        backgroundColor: "#000000"
    },

    tabs: {
        textAlign: 'center',
    }
}));

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export function NavBar(props) {
    const { logout } = useAuth0();

    const [width] = useWindowSize();

    const { openTools, addedTrigger } = props;

    // const [isMobile, setIsMobile] = useState(false)

    const classes = useStyles();

    /* If the viewport is more narrow than the breakpoint render the
       mobile component, else render the desktop component */

    return (
        <div>
            {/* {openTools &&
                <Slide in={openTools} direction="down" mountOnEnter unmountOnExit>
                    <div className={classes.toolbar}>
                        <ElevationScroll >
                            <Paper className={classes.toolPaper} square={true}>
                                <CustomToolbar handleChangeDescription={handleChangeDescription} addedTrigger={addedTrigger} />
                            </Paper>
                        </ ElevationScroll>
                    </ div>
                </Slide>
            } */}
            {/* <ElevationScroll {...props}> */}
            <AppBar className={classes.root}>
                <Toolbar disableGutters variant="dense">
                    <Grid container alignItems="center" justify="center" spacing={1}>
                        <Grid item md={2}>
                            <div className={classes.logoContainer}>
                                <img className={classes.logo} src={'https://www.haccpassurance.com/wp-content/uploads/2020/08/PNG-WHITE.png'} alt="HACCP Assurance Logo" />
                            </div>
                        </Grid>
                        <Grid className={classes.tabs} item md={9}>
                            {openTools &&
                                <CustomToolbar addedTrigger={addedTrigger} />
                            }
                            {/* <CustomTabs
                                currentTab={currentTab}
                                handleTabChange={handleTabChange}
                            /> */}
                        </Grid>

                        <Grid item md={1}>
                            <Button aria-label="logout" variant="outlined" className={width > 620 ? classes.menuButton : classes.condensedButton} color="inherit" onClick={() => logout()}>Logout</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {/* </ElevationScroll > */}
        </div>
    );
};