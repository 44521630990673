import React from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CustomDatePicker } from '../CustomDatePicker'



export function CustomTableToolbar(props) {

    const {
        rowsData,
        handleFirstTen,
        setBatchIDSearch,
        onEnter,
        batchIDSearch,
        handleSearchBatchID,
        handleDateChange,
        selectedDate,
        setOpenDatePicker,
        openDatePicker,
        searchLoading
    } = props;

    const classes = useStyles();

    return (
        <div>
            {rowsData.length < 10 &&
                <div>
                    <Button aria-label="view first ten" onClick={handleFirstTen}>View First Ten</Button>
                </div>
            }
            <Grid container alignItems="center" justify="center" spacing={1}>
                <Grid item md={5}>
                    {searchLoading ? <CircularProgress /> :
                        <TextField
                            className={classes.description}
                            label="Search Batch ID"
                            color="primary"
                            variant="outlined"
                            margin="dense"
                            onChange={e => setBatchIDSearch(e.target.value)}
                            onKeyDown={onEnter}
                            value={batchIDSearch}
                        />
                    }
                </Grid>
                <Grid className={classes.searchButton} item md={2}>
                    <IconButton onClick={e => handleSearchBatchID(batchIDSearch, e)} className={classes.iconButton} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Grid>

                <Grid item md={5}>
                    <CustomDatePicker handleDateChange={handleDateChange} selectedDate={selectedDate} label="Search Collected Date" disabled={batchIDSearch ? true : false} setOpen={setOpenDatePicker} open={openDatePicker} />
                </Grid>
            </Grid>
        </div>
    )
}


const useStyles = makeStyles((theme) => ({

    addButton: {
        'text-transform': 'none',
        marginRight: theme.spacing(1)
    },
    longField: {
        width: '300px'
    },

    saveButton: {
        'text-transform': 'none',
        marginRight: theme.spacing(10)
    },
    searchButton: {
        textAlign: 'left'
    }

}));
