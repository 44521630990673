//With time
export function formatDate(date) {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + " " + strTime;
}

//Without time
export function formatToDate(date) {
	// var minutes = date.getMinutes();
	// minutes = minutes < 10 ? '0' + minutes : minutes;
	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
}
