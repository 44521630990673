import React from 'react';

import { withStyles } from "@material-ui/core/styles";

import logo from '../../../media/HACCPBlue.png'
import signature from '../../../media/signature.PNG'
import { formatDate } from '../../../shared/helper-functions/dates'

const styles = theme => ({
    page: {
        transform: 'rotate(90deg)'
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    logoContainer: {
        width: '300px',
        height: '20px',
        marginBottom: theme.spacing(5)
    },
});

class PrintableComponent extends React.Component {

    render() {
        return (
            <div>
                <div style={{ fontSize: '7pt', display: 'table', width: '100%', 'tableLayout': 'fixed' }}>

                    <div style={{ display: 'table-cell', width: '200px', height: '20px', marginBottom: '10px' }}>
                        <img style={{ width: '100%', height: 'auto', }} src={logo} alt="HACCP Assurance Logo" />
                    </div>
                    <div style={{ textAlign: 'center', display: 'table-cell', fontSize: '17pt', width: '350px', height: '20px' }}>
                        <span>CERTIFICATE OF ANALYSIS</span>
                    </div>
                    <div style={{ display: 'table-cell', top: '10px', right: '15px', fontSize: '7pt', textAlign: 'right', width: '250px', height: '20px', margin: '10px' }}>
                        <span>683 South Mountain Boulevard, Mountaintop, PA 18707</span>
                        <br />
                        <span>Phone: (800) 265-0456 Fax: (800) 265-9794</span>
                        <br />
                        <span>Email: info@haccpassurance.com</span>
                    </div>
                </div>

                <div style={{ fontSize: '7pt', display: 'table', width: '100%', 'tableLayout': 'fixed' }}>
                    <div style={{ display: 'table-cell', fontSize: '7pt', textAlign: 'left', width: '200px', height: '20px' }}>
                        <span>Client</span>
                        <br />
                        <span>Nicholas Meats, LLC.</span>
                        <br />
                        <span>508 East Valley Road</span>
                        <br />
                        <span>Loganton, PA 17747-9207</span>
                    </div>
                    <div style={{ display: 'table-cell', textAlign: 'center', fontSize: '7pt', height: '20px', paddingRight: '200px' }}>
                        <span>C.O.A. Date</span>
                        <br />
                        <span>{formatDate(new Date())}</span>
                    </div>
                </div>

                <div style={{ fontSize: '7pt', display: 'table', width: '100%', 'tableLayout': 'fixed' }}>
                    <div style={{ display: 'table-cell', textAlign: 'center', fontSize: '7pt', width: '500px', height: '20px' }}>
                        <span>Reported To</span>
                        <br />
                        <span>Duane Eichenlaub [deichenlaub@nicholasmeats.com], QA [QA@nicholasmeats.com]</span>
                    </div>
                </div>

                <div style={{ padding: '5px', fontSize: '7pt', display: 'table', width: '100%', 'tableLayout': 'fixed' }}>
                    <div style={{ display: 'table-cell' }}>
                        <span>Raw Material Notification Prepared For: {this.props.rawMaterial}</span>
                    </div>
                    <div style={{ display: 'table-cell' }}>
                        <span>Sold to PO #: {this.props.soldNumber}</span>
                    </div>
                    <div style={{ display: 'table-cell' }}>
                        <span>Ship to PO #: {this.props.shipNumber}</span>
                    </div>
                </div>

                <div style={{ fontSize: '7pt', display: 'table', width: '100%', 'tableLayout': 'fixed' }}>
                    <div style={{ textAlign: 'center', display: 'table-cell' }}>
                        <span><b><u>Certificate of Analysis - Est. #4465</u></b></span>
                    </div>
                </div>

                <div style={{ float: 'left' }}>
                    <table>
                        <thead style={{ fontSize: '7pt' }}>
                            <tr>
                                <th style={{ paddingLeft: '20px' }}>QC Lot #:</th>
                                <th style={{ paddingLeft: '20px' }}>LIMS #:</th>
                                <th style={{ paddingLeft: '20px' }}>Production Date:</th>
                                <th style={{ paddingLeft: '20px' }}>Description:</th>
                                <th style={{ paddingLeft: '20px' }}>Sample Type:</th>
                                <th style={{ paddingLeft: '20px' }}>Analysis:</th>
                                <th style={{ paddingLeft: '20px' }}>Method Reference:</th>
                                <th style={{ paddingLeft: '20px' }}>Result:</th>
                                <th style={{ paddingLeft: '20px' }}>Units:</th>
                                <th style={{ paddingLeft: '20px' }}>Test Date:</th>
                                <th style={{ paddingLeft: '20px' }}>Tech:</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '7pt', textAlign: 'center' }}>
                            {this.props.addedSamples.length > 0 && this.props.addedSamples.map((sample, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{sample.sample.CustomerSampleID[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.attr.ID}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.CollectedDate}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.description ? sample.description : sample.sample.Description}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.SampleType[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.Analyses[0].Requirement[0].Analysis[0].ReportName[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.Analyses[0].Requirement[0].Analysis[0].Method[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.Analyses[0].Requirement[0].Analysis[0].ResultType[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.Analyses[0].Requirement[0].Analysis[0].Units[0]}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.CompletedDate[0] || sample.dbDate}</td>
                                        <td style={{ paddingLeft: '20px' }}>{sample.sample.Analyses[0].Requirement[0].Analysis[0].Technician[0]}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign: 'left', paddingTop: '25px', float: 'left' }}>
                    <span>Respectfully Submitted,</span>
                    <br />
                    <span>HACCP Assurance Services, Inc.</span>
                    <br />
                    <div style={{ width: '200px', height: '20px', marginBottom: '10px' }}>
                        <img style={{ width: '100%', height: 'auto', }} src={signature} alt="Signature" />
                    </div>
                    <br />
                    <span>Dustin Smith, Operations Manager</span>
                </div>

            </div >
        );
    }
}

export default withStyles(styles)(PrintableComponent);