import React, { useCallback, useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DataTable } from '../shared/components/DataTables/DataTable'
import { AddedDataTable } from '../shared/components/DataTables/AddedDataTable'
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from '../shared/components/PrintableComponent/PrintableComponent'
import { formatDate } from '../shared/helper-functions/dates'
import Divider from '@material-ui/core/Divider';
import PrintIcon from '@material-ui/icons/Print';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { apiRequest } from '../shared/helper-functions/apiRequest';


export function Dashboard(props) {
	const classes = useStyles();

	const {
		nextPageLoading,
		handleOpenTools,
		openTools,
		added,
		selectedRows,
		setSelectedRows,
		page,
		handleChangePage,
		handleSearchBatchID,
		rowsData,
		handleSearchDate,
		displayError,
		handleFirstTen,
		searchLoading,
	} = props;

	const [addedSamples, setAddedSamples] = useState([]);
	const [soldNumber, setSoldNumber] = useState('');
	const [shipNumber, setShipNumber] = useState('');
	const [description] = useState('');
	const [rawMaterial, setRawMaterial] = useState('');
	const [samplesOnceTrue, setSamplesOnceTrue] = useState(false);
	const [coaDate] = useState(formatDate(new Date()));
	const [rowsExpanded, setRowsExpanded] = useState(null);
	const [batchIDSearch, setBatchIDSearch] = useState('');
	const [dateSearch, setDateSearch] = useState('');

	const [selectedDate, setSelectedDate] = useState(new Date());

	const [batchTableState, setBatchTableState] = useState(
		{ //Dynamic collection of props that are needed between table refreshes.
			columns: [],
			expandedRows: [],
			searchText: ''
		}
	);

	const [expandState, setExpandState] = React.useState(
		{ //Dynamic collection of props that are needed between table refreshes.
			selectedRows: [],
			columns: [],
			searchText: '',
			filterList: [],
		}
	);

	const [addedTableState, setAddedTableState] = React.useState(
		{ //Dynamic collection of props that are needed between table refreshes.
			selectedRows: [],
			columns: []
		}
	);

	const getMuiTheme = () => createMuiTheme({
		overrides: {
			MuiTableRow: {
				root: {
					'&$selected': {
						backgroundColor: 'rgb(148,216,238, .5)',
					},
					'&$selected:hover': {
						backgroundColor: 'rgb(148,216,238, .8)',
					}
				}
			},
		}
	})

	function handleBatchTableChange(action, tableState) {

		if (action === 'expandRow') {
			setExpandState({
				columns: tableState.columns, //We can pull column-specific options from this array, like display and sortDirection
				selectedRows: [],
				searchText: '',
				filterList: [],
			});
			setBatchTableState({
				columns: tableState.columns, //We can pull column-specific options from this array, like display and sortDirection
				expandedRows: tableState.expandedRows,
				searchText: tableState.searchText
			});
			handleOpenTools([]);
		}
		else if (action !== 'propsUpdate') { //Store new addedTableState only if not updating props
			setBatchTableState({
				columns: tableState.columns, //We can pull column-specific options from this array, like display and sortDirection
				expandedRows: tableState.expandedRows,
				searchText: tableState.searchText
			});
		}
	};

	function handleExpandChange(action, tableState) {
		if (action !== 'propsUpdate') { //Store new addedTableState only if not updating props
			setExpandState({
				columns: tableState.columns, //We can pull column-specific options from this array, like display and sortDirection
				selectedRows: tableState.selectedRows,
				searchText: tableState.searchText,
				filterList: tableState.filterList,
			});
		}
	};

	function getExpandRowsSelected(rowMeta) {
		let rowsSelected = [];

		expandState.selectedRows.data && expandState.selectedRows.data.map((row, index) => {
			return (
				rowsSelected.push(row.dataIndex)
			)
		})
		return rowsSelected;
	}

	function getExpandColumns() {

		let columns = [
			{
				name: "attr.ID",
				label: "LIMS ID",
				options: {
					filter: false,
					sort: true,
					sortDirection: 'none',
					display: true,
					filterList: []
				}
			},
			{
				name: "CustomerSampleID.0",
				label: "Sample ID",
				options: {
					filter: false,
					sort: false,
					sortDirection: 'none',
					display: true,
					filterList: []

				}
			},
			{
				name: "Description.0",
				label: "Description",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true,
					filterList: []
				}
			},
		];

		//Loop through columns and assign all column-specific settings that need to persist through a data refresh
		for (let i = 0; i < columns.length; i++) {
			//Assign the filter list to persist
			columns[i].options.filterList = expandState.filterList[i];
			if (expandState.columns[i] !== undefined) {
				//If 'display' has a value in addedTableState, assign that, or else leave it alone
				if (expandState.columns[i].hasOwnProperty('display'))
					columns[i].options.display = expandState.columns[i].display;
				//If 'sortDirection' has a value in addedTableState, assign that, or else leave it alone
				if (expandState.columns[i].hasOwnProperty('sortDirection')) {
					//The sortDirection prop only permits sortDirection for one column at a time
					if (expandState.columns[i].sortDirection !== 'none')
						columns[i].options.sortDirection = expandState.columns[i].sortDirection;
				}
			}
		}
		return columns;
	}

	function handleAddedTableChange(action, tableState) {

		if (action !== 'propsUpdate') { //Store new addedTableState only if not updating props
			setAddedTableState({
				selectedRows: tableState.selectedRows,
				columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
			});
		}
	};

	function getBatchColumns() {

		let columns = [

			{
				name: "sampleBatch",
				label: "Batch ID",
				options: {
					filter: true,
					sort: false,
					sortDirection: 'none',
					display: true
				}
			},
			{
				name: "sampleCollectedDate",
				label: "Collected Date",
				options: {
					filter: true,
					sort: false,
					sortDirection: 'none',
					display: true
				}
			},

		];

		//Loop through columns and assign all column-specific settings that need to persist through a data refresh
		for (let i = 0; i < columns.length; i++) {
			//Assign the filter list to persist
			// columns[i].options.filterList = addedTableState.filterList[i];
			if (batchTableState.columns[i] !== undefined) {
				//If 'display' has a value in addedTableState, assign that, or else leave it alone
				if (batchTableState.columns[i].hasOwnProperty('display'))
					columns[i].options.display = batchTableState.columns[i].display;
				//If 'sortDirection' has a value in addedTableState, assign that, or else leave it alone
				if (batchTableState.columns[i].hasOwnProperty('sortDirection')) {
					//The sortDirection prop only permits sortDirection for one column at a time
					if (batchTableState.columns[i].sortDirection !== 'none')
						columns[i].options.sortDirection = batchTableState.columns[i].sortDirection;
				}
			}
		}
		return columns;
	}

	function getRowsExpanded() {
		let rowsExpanded = [];
		batchTableState.expandedRows.data && batchTableState.expandedRows.data
			.map((row, index) => {
				rowsExpanded = [row.dataIndex]
				return null;
			})
		return rowsExpanded;
	}

	//Return all columns, their props, and any current state-related changes
	function getColumns() {
		//Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
		let columns = [
			{
				name: "sampleBatch",
				label: "Batch ID",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true
				}
			},
			{
				name: "sample.attr.ID",
				label: "LIMS ID",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true
				}
			},
			{
				name: "sample.CustomerSampleID.0",
				label: "Sample ID",
				options: {
					filter: false,
					sort: false,
					display: true
				}
			},
			{
				name: "sampleCollectedDate",
				label: "Collected Date",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true
				}
			},
			{
				name: "sample.Description.0",
				label: " Original Description",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true
				}
			},
			{
				name: "description",
				label: "New Description",
				options: {
					filter: true,
					sort: true,
					sortDirection: 'none',
					display: true
				}
			},
		];

		//Loop through columns and assign all column-specific settings that need to persist through a data refresh
		for (let i = 0; i < columns.length; i++) {
			//Assign the filter list to persist
			// columns[i].options.filterList = addedTableState.filterList[i];
			if (addedTableState.columns[i] !== undefined) {
				//If 'display' has a value in addedTableState, assign that, or else leave it alone
				if (addedTableState.columns[i].hasOwnProperty('display'))
					columns[i].options.display = addedTableState.columns[i].display;
				//If 'sortDirection' has a value in addedTableState, assign that, or else leave it alone
				if (addedTableState.columns[i].hasOwnProperty('sortDirection')) {
					//The sortDirection prop only permits sortDirection for one column at a time
					if (addedTableState.columns[i].sortDirection !== 'none')
						columns[i].options.sortDirection = addedTableState.columns[i].sortDirection;
				}
			}
		}

		return columns;
	}

	function getRowsSelected() {
		let rowsSelected = [];
		addedTableState.selectedRows.data && addedTableState.selectedRows.data.map((row, index) => {
			rowsSelected.push(row.dataIndex)
			return null;
		})
		return rowsSelected;
	}

	function getExpandSearchText() {
		return expandState.searchText;
	}

	function getBatchSearchText() {
		return batchTableState.searchText;
	}



	function saveDescription(description) {

		let array = [];

		addedSamples && addedSamples.map((added, addedIndex) => {

			let found = false;

			addedTableState.selectedRows.data && addedTableState.selectedRows.data.map((row, index) => {
				if (addedIndex === row.dataIndex) {
					var newInput = Object.assign({},
						addedSamples[row.dataIndex], { 'description': description }
					);
					array.push(newInput)
					found = true
				}
				return null;
			});
			if (found === false) {
				array.push(added)
			}
			return null;
		})
		setAddedSamples(array)
	}

	let previousPrinted = [];

	let count = 0;

	function handleAfterPrint() {

		let addedSamplesWithName = { COA: addedSamples, rawMaterial: rawMaterial, soldToPO: soldNumber, shipToPO: shipNumber, coaDate: new Date() }

		if (count > 0) {
			previousPrinted = addedSamples;
		}
		else
			count = count + 1

		if (addedSamples.length > 0 && addedSamples !== previousPrinted && count > 0) {
			apiRequest('post', 'generatedReports', function callback() {
				console.log("Added to the database!")
			}, addedSamplesWithName)

		}
	}

	const handleAddSamples = () => {

		const sampleBatch = rowsData[rowsExpanded[0]].sampleBatch;
		const sampleCollectedDate = rowsData[rowsExpanded[0]].sampleCollectedDate;
		const dbDate = formatDate(new Date(rowsData[rowsExpanded[0]].dbDate));

		selectedRows && selectedRows.map((row, index) => {
			const sample = rowsData[rowsExpanded[0]].samples[row.dataIndex]
			const data = { sampleBatch, sampleCollectedDate, sample, dbDate }
			setAddedSamples(addedSamples => addedSamples.concat(data))
			return null;
		})
		setSamplesOnceTrue(true);

		setSelectedRows([])

	}

	useEffect(() => {
		if (added > 0)
			handleAddSamples()
	}, [added])

	function handleRowsExpanded(allRowsExpanded) {
		setRowsExpanded(allRowsExpanded)
	}

	function handleRawMatChange(e) {
		setRawMaterial(e.target.value)
	}

	function handleRemoveSamples(rowsDeleted) {
		setAddedTableState(addedTableState => ({ ...addedTableState, selectedRows: [] }));
		var array = [...addedSamples];

		for (var i = addedSamples.length - 1; i >= 0; i--) {
			//Parses samp and splices if internaldata === "0"
			//Iterating backwards because splice messes with array size
			for (var j = rowsDeleted.length - 1; j >= 0; j--) {
				var rowIndex = rowsDeleted[j]

				if (rowIndex === i) {
					array.splice(i, 1);
				}
			}
		}
		setAddedSamples(array);
	}

	function handleDateChange(value) {
		console.log("handleDateChange", value)
		setSelectedDate(value)
		handleSearchDate(value)
	}

	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => handleAfterPrint()
	});

	return (
		<div>
			<div className={classes.content}>
				<div className={classes.tables}>
					<Grid container alignItems="center" justify="center" spacing={1}>
						<Grid className={classes.selectTableGrid} item md={6}>
							{rowsData.length > 0 || displayError === true ?
								<MuiThemeProvider theme={getMuiTheme()}>
									<DataTable
										title={"Select Samples"}
										rowsData={rowsData}
										handleOpenTools={handleOpenTools}
										openTools={openTools}
										handleRowsExpanded={allRowsExpanded => handleRowsExpanded(allRowsExpanded)}
										rowsExpanded={rowsExpanded}
										handleBatchTableChange={handleBatchTableChange}
										getBatchColumns={getBatchColumns}
										getRowsExpanded={getRowsExpanded}
										handleExpandChange={handleExpandChange}
										getExpandColumns={getExpandColumns}
										getExpandRowsSelected={getExpandRowsSelected}
										getExpandSearchText={getExpandSearchText}
										getBatchSearchText={getBatchSearchText}
										handleChangePage={(page) => handleChangePage(page)}
										page={page}
										handleSearchBatchID={handleSearchBatchID}
										setBatchIDSearch={setBatchIDSearch}
										setDateSearch={setDateSearch}
										dateSearch={dateSearch}
										batchIDSearch={batchIDSearch}
										handleDateChange={handleDateChange}
										selectedDate={selectedDate}
										handleSearchDate={handleSearchDate}
										handleFirstTen={handleFirstTen}
										searchLoading={searchLoading}
									/>
								</MuiThemeProvider>
								:
								<CircularProgress
									className={classes.loading}
								/>
							}
							{nextPageLoading && <LinearProgress />}

						</Grid>
						<Grid item md={6}>
							<MuiThemeProvider theme={getMuiTheme()}>
								<AddedDataTable
									title="Added Samples"
									addedSamples={addedSamples ? addedSamples : null}
									handleRemoveSamples={(rowsDeleted) => handleRemoveSamples(rowsDeleted)}
									getRowsSelected={getRowsSelected}
									getColumns={getColumns}
									handleAddedTableChange={handleAddedTableChange}
									saveDescription={(description) => saveDescription(description)}
								/>
							</MuiThemeProvider>

						</Grid>
					</Grid>
				</div>
				{samplesOnceTrue &&
					<>
						<Divider className={classes.divider} />
						<div className={classes.inputContainer}>
							<TextField className={classes.longInputField} id="rawMaterial" label="Raw Material Notification Prepared For" value={rawMaterial} onChange={handleRawMatChange} />
							<TextField className={classes.inputField} id="soldNumber" label="Sold to PO #" value={soldNumber} onChange={e => setSoldNumber(e.target.value)} />
							<TextField className={classes.inputField} id="shipNumber" label="Ship to PO #" value={shipNumber} onChange={e => setShipNumber(e.target.value)} />
						</div>
						<div>
							<div className={classes.pdfView}>
								<PrintableComponent
									currentDate={coaDate}
									ref={componentRef}
									soldNumber={soldNumber}
									shipNumber={shipNumber}
									description={description}
									rawMaterial={rawMaterial}
									sampleBatch={addedSamples.length > 0 ? addedSamples[0].sampleBatch : ''}
									addedSamples={addedSamples}
								/>
							</div>
						</div>
						<Fab aria-label="search" size="large" variant="extended" onClick={handlePrint} color="secondary" aria-label="add" className={classes.printButton}>
							<PrintIcon className={classes.printIcon} />
							<span><b>Print</b></span>
						</Fab>
					</>
				}
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(4),
		textAlign: 'center'
	},
	content: {
		marginTop: theme.spacing(8)
	},
	contentOpenTools: {
		marginTop: theme.spacing(15)
	},
	tables: {
		margin: theme.spacing(3)
	},
	pdfView: {
		textAlign: 'center',
		position: 'relative',
		width: '297mm',
		'min-height': '210mm',
		padding: '20mm',
		margin: '10mm auto',
		border: '1px #D3D3D3 solid',
		'border-radius': '5px',
		background: '#fbfbf8',
		'box-shadow': '0 0 5px rgba(0, 0, 0, 0.1)',
		overflow: 'scroll',
		'overflow-y': 'hidden',
		'overflow-x': 'hidden',
	},
	button: {
		'text-transform': 'none',
		width: '100%'
	},
	addButton: {
		position: 'fixed'
	},
	page: {
		flexDirection: 'row',
		backgroundColor: '#E4E4E4'
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	divider: {
		margin: theme.spacing(3)
	},
	printButton: {
		position: 'fixed',
		right: '25px',
		bottom: '25px',
		fontSize: '15pt',
		'text-transform': 'none',
		color: '#181b4e'
	},
	printIcon: {
		color: '#181b4e',
		marginRight: theme.spacing(1)
	},
	inputField: {
		maxWidth: '150px',
		margin: theme.spacing(1)
	},
	longInputField: {
		width: '325px',
		margin: theme.spacing(1)
	},
	inputContainer: {
		textAlign: 'center'
	},
	loading: {
		textAlign: 'center'
	},
	selectTableGrid: {
		textAlign: 'center'
	},
	toolPaper: {
		textAlign: 'center',
		backgroundColor: '#000000',
	},
	toolbar: {
		'z-index': '1000',
		backgroundColor: 'rgb(37,37,107, .5)',
	},
	elevationScroll: {
		textAlign: 'center'
	},
	buttons: {
		textAlign: 'center'
	},

	tableDescription: {
		'font-size': '12px',
	},
	tableTitle: {
		'font-size': '20px',
	},
	tableHeader: {
		marginTop: theme.spacing(2)
	}

}));